// import logo from './logo.svg';
import './App.css';
import NavBar from './Components/NavBar';
import Footer from './Components/Footer';
import Main from './Pages/main.js';


function App() {
  return (
  <>
 <NavBar/>
	<Main></Main>
	<Footer/>
  </>
  );
}

export default App;
