import React from 'react';
import '../Styles/footer.css';

function Footer() {
	return (
		<div className="footer">
				<div className="footer-text">
					Contact Information: For enquires please email <a className="email-href" href="mailto:info@coinapps.ca">info@coinapps.ca</a><br/> 
								Head Office: Vancouver BC, Canada<br/> 
								Regional Office: Cape Town, South Africa<br/> 
								Copyright 2021 CoinApps<br/>
								Website Designs By KCB Software & Design
				</div>
		</div>
	);
}

export default Footer;