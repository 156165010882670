import React, { Component } from 'react';
import Logo from "../images/logo.png";
import '../Styles/Navbar.css';


class NavBar extends Component {
	render() {
		return (
			<body>
				<div className="navbar">
					<img className="logo"src={Logo} alt=""></img>

				</div>
			</body>
		);
	}
}

export default NavBar;