import React from 'react';
import Particles from 'react-tsparticles';
import ParticleConfig from '../config/particle-config';
import '../Styles/particle.css';

function ParticleBackground() {
	return (
		<div id="particle-container">
			<Particles params={ParticleConfig} id="particles" ></Particles>
		</div>
	);
}

export default ParticleBackground;