import React, { useEffect } from 'react';
import ParticleBackground from '../Components/ParticleBackground';
import {useSpring,animated} from 'react-spring';
import '../Styles/main.css';
import Typist from 'react-typist';
import Aos from "aos";
import "aos/dist/aos.css";
import Block from '../images/block.png';
import Flash from '../images/flash.png';
import {Helmet} from "react-helmet";


function Main() {
	useEffect(()=>{
		Aos.init({duration: 2000});
	});
	const fadeMove = useSpring({
		config: { duration:3000},
		from:{ marginRight:900},
		to:{ marginRight:350}})
		  ;
	return (
		<>
			
				<Helmet>

					<title>CoinApps Tech Inc</title>
					<meta name="descrpition" content="Coinapps uses blockchain techhnology, digital wallets, and cryptocurrency, FlashCoin, to introduce a modern way of making payment."></meta>
					<meta name="robots" content="noodp, noydir" />
				</Helmet>
			{/* <ParticleBackground></ParticleBackground> */}
			<body>
			{/* <noscript><iframe title= "Google Tag Manager"src="https://www.googletagmanager.com/ns.html?id=GTM-KJWB3XH"
height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript> */}

				<div className="type-container"><Typist><div className="title">The Blockchain Technology Solution for the 21st Century.</div></Typist>
					</div>
					<img className="topImage" src="https://res.cloudinary.com/kcb-software-design/image/upload/v1639083708/coinapps/11_a0rbod.png" alt="CoinApps cryptocurrency"/>
					<animated.div style={fadeMove} className="secondary-title-container">
						<div className="secondary-title">Digital wallets and blockchain technology bring financial inclusion of the unbanked, micro,
							small and medium Enterprises</div>
					</animated.div>

					<div className="first-container-box">
						<div className="box-container">
						{/* <img className="block-img" src={Block} alt="crypto"></img> */}
							<div className="box" data-aos="fade-right"><div className="top-texts">
							CoinApps have developed an integrated blockchain and digital wallet technology to create a
							secure and easy to use solution for banks, co-operatives, loyalty platforms and payment
							providers.<br/><br/>
							The CoinApps blockchain is based on open source technology, but rewritten for the scalability 
							and performance demanded by real world applications and proprietary wallet features.<br/><br/>
							Our technology is proven in real, in-field use powering Flashcoin.io! Flashcoin has generated
								over 150,000 wallets and more than 3M transactions. Transactions are fast (under 10 seconds 
								settlement) and cryptographically secure.</div></div>
						<img src="https://res.cloudinary.com/kcb-software-design/image/upload/v1639083707/coinapps/blockchain-top_v9ga4v.png" alt="blockchain technology" className="firstpointpic"/>
							{/* <div className="box box2" data-aos="fade-left"> 
								<div className="top-texts">The CoinApps blockchain is based on open source technology, but rewritten for the scalability 
							and performance demanded by real world applications and proprietary wallet features. </div>
							</div> */}
						</div>
						<div className="third-box-container">
							{/* <div className="box box3" data-aos="fade-up">
								<div className="top-third-text">Our technology is proven in real, in-field use powering Flashcoin.io! Flashcoin has generated
								over 150,000 wallets and more than 3M transactions. Transactions are fast (under 10 seconds 
								settlement) and cryptographically secure.
								</div> 
							</div>*/}
						</div>
					</div>
					
							<div className="container-containers">
								<div className="type-container advantage-container"><Typist><div className="advantage">Advantages of our blockchain and integrated wallet includes...</div></Typist></div>
								<div className="advantage-points-container advantage-mobile">
									<div  className="advantage-box " data-aos="flip-up">
										<div className="advantage-tites">No Intermediation</div>
										<div className="advantage-points">
											Blockchain drastically reduces settlement time, which in turn means that 
											customers will be able to access their funds when they need it
										</div>
									</div>
									<div  className="advantage-box" data-aos="flip-up">
										<div  className="advantage-tites">Process Efficiency & Speed</div>
										<div className="advantage-points">
											Storing transactions in blockchain could eliminate the need for 
											complicated procedures and clearing houses.
										</div>
									</div>
									<div  className="advantage-box" data-aos="flip-up">
										<div  className="advantage-tites">Automation</div>
										<div className="advantage-points">
											The use of digital technology enables automated execution and will simplify 
											complex procedures and the verification process
										</div>
									</div>
									<div className="advantage-box" data-aos="flip-up">
										<div  className="advantage-tites">New Transaction Models</div>
										<div className="advantage-points">
											 The integrated wallet and blockchain enable new ways to transact, 
											e.g. stablecoins, central bank digital coins and special purpose coins
										</div>
									</div>
								</div>
							</div>
							<div className="trythis">
								<div className="digital-container-full">
									<div className="advantage digital-title">Digital Wallet features includes:</div>
									
										<div className="advantage-points-container wallet-actual-container mobile-wallet-container">
											<div className="advantage-box wallet"  data-aos="flip-down">Our digital wallet supports all major platforms; Android, iOS and web browser. <br/> </div>
											<div className="advantage-box wallet"  data-aos="flip-down">Wallets are simple to use with its user Interface to keys and blockchain.<br/>  </div>
											<div className="advantage-box wallet" data-aos="flip-down">
												Wallet stores keys and transacts using cloud based state-of-the-art technology with 
												send, receive, or request real time notifications of the transactions.<br/> 
											</div>
										<div className="advantage-box wallet"  data-aos="flip-down"> Our technology has scalability and reliability and state of the art security<br/> </div>
										</div>
										<img src="https://res.cloudinary.com/kcb-software-design/image/upload/v1639083708/coinapps/Loans_jblyia.png" alt="digital wallet" className="wallet-image"/>
									
								</div>
							</div>
							<div className="new-blockchain-container">
								<div className="advantage blockchain-title ">Blockchain features includes:</div>
								<div>
									<div className="blockchain-container">
										<div className="advantage-box blockchain bcm" data-aos="fade-right" data-aos-duration="500">High performance; ~25,000 txn/sec, less than 5 sec confirmation</div><br/> 
										<div className="advantage-box blockchain bcm" data-aos="fade-left" data-aos-duration="500">Pre-mined coins for transaction speed</div> <br/> 
										<div className="advantage-box blockchain bcm" data-aos="fade-right" data-aos-duration="500">
											Administration tools and reporting using the cryptographically secure blockchain 
											controlled by financial institution<br/> 
										</div>
										</div>
										<img src="https://res.cloudinary.com/kcb-software-design/image/upload/v1639083709/coinapps/1_btfmoh.png" alt="coinapp blockchain" className="blockchain-image"/>
								</div>
							</div>
	
								<div className="flash-contains">
									
									<div className="advantage flash">
									<div className="mobile-title-flash">Flashcoin.io powered by Coin Apps Tech</div></div>
									<img className="flashmobileimage firstflash"  data-aos="flip-left" src={Flash} alt="Flash Coin FlashCoin"/><br/>
					
									 	<div className="flashcontain">
										 	<div className="advantage-box flash-box" data-aos="zoom-in-up">
											 	FLASH COIN is designed for everyday ease of use for MERCHANTS, CONSUMERS and 
												P2P<br/> 
											 </div>
											<div className="advantage-box flash-box"data-aos="zoom-in-up">
												FLASH coin is growing because of its real-world applications and integration with POS 
												systems<br/> 
											</div>
											<div className="advantage-box flash-box"data-aos="zoom-in-up">
												Safety and Security for our users is one of our core values and therefore continually 
												reviewed and updated <br/> 
											</div>
											<img className="flashmobileimage flashlast"  data-aos="flip-left"src={Flash}  data-aos-once="false" alt="Flash Coin FlashCoin"/><br/>
										 </div>
										 <img src="https://res.cloudinary.com/kcb-software-design/image/upload/v1639083708/coinapps/flash_full_mrsswf.png" alt="Flash Coin FlashCoin" className="flash-full-image"/>
									 
								</div>
							
							<div className="coinApp-contain">
								<div className="advantage coinapptech">About Coin Apps Tech Inc.</div>
								<div>
									<div className=" advantage-points-container coin">
										<div className="coinpara" data-aos="zoom-in-up">
											We are a Canadian incorporated company, privately owned and headquartered in Vancouver 
											BC Canada. We are developers of innovative and cutting-edge wallet technology and a 
											Canadian leader in blockchain remittance and payment technology.
											Led by industry veterans, the management team is made up of industry veterans as well as 
											business leaders, serving the rapidly growing digital payments industry in Africa, Latin America 
											and South East Asia, and offering a ubiquitous solution for banking the unbankable.<br/> 
										</div>
									</div>
											<img src="https://res.cloudinary.com/kcb-software-design/image/upload/v1639083708/coinapps/business_ec3csy.png" alt="CoinApps Coin Apps Tech" className="para-image"/>
								</div>
							</div>
							

			</body>
		</>
	);
}

export default Main;